.projects-page {
  min-height: 100vh;
  background-color: #121212;
  padding: 150px 60px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-header {
  text-align: center;
  max-width: 700px;
  margin-bottom: 80px;
}

.projects-page h2 {
  margin-bottom: 24px;
  font-size: 6rem;
  color: #ffffffd8;
  font-weight: 400;
}

.description-prj {
  font-size: 18px;
  font-weight: 300;
  color: #979797;
}

.main-project-image {
  width: 100vw; /* Full viewport width */
  padding-left: 60px; /* Center the image */
  padding-right: 60px;
  margin-bottom: 80px;
}

.main-project-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}



.project-content-wrapper,
.gallery-container-wrapper,
.navigation-links-wrapper ,
.projects-body-text{
  width: 100%;
  max-width: 800px;
  /* max-width: 100px; */
}


.project-main {
  flex: 2;
  padding-right: 100px;
  margin-right: 200px;
}

.project-main h3 {
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 16px;
  font-weight: 400;
}



.project-main p {
  font-size: 20px;
  font-weight: 300;
  color: #979797;
  margin-bottom: 40px;
}

.projects-body-text h3 {
  font-size: 16px;
  /* color: #ffffff; */
  color: #979797;
  margin-bottom: 16px;
  font-weight: 400;
}

.projects-body-text p {
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 40px;
}

.project-meta {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.meta-item {
  margin-bottom: 30px;
}

.meta-item h4 {
  font-size: 14px;
  color: #979797;
  margin-bottom: 8px;
  font-weight: 300;
}

.meta-item p {
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Adds space between tags */
}

.tag {
  padding: 6px 8px;
  font-size: 0.88rem;
  background: #2e2e2e;
  letter-spacing: 2%;
  color: #cacacacc;
  font-weight: 320;
  border: 0.6px solid rgba(255, 255, 255, 0.057);
  text-shadow: 0px -0.5px 0.25px rgba(0, 0, 0, 0.29);
  border-radius: 8px;
}

.audience-container {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Adds space between audience items */
}

.audience-item {
  font-size: 1rem;
  color: #fff;
  font-weight: 300;
}

.visit-website {
  display: inline-block;
  background-color: #f0f0f0;
  color: #333;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  border-radius: 8px;
  margin-bottom: 40px;
  margin-top: -16px;
}

.visit-website:hover {
  background-color: #e0e0e0;
}

.gallery-container-wrapper {
  width: 100%;
}

.gallery-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding-bottom: 32px;
  cursor: pointer;
}

.gallery-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.navigation-links-wrapper {
  width: 100%;
  padding: 0 60px;
}

.navigation-links {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 80px;
}

.nav-link {
  text-decoration: none;
  color: #979797;
  font-size: 1.2em;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ffffff;
}

/* bullet points */

ul.problem-state {
  list-style-type: disc; /* Use bullet points */
  padding-left: 20px;    /* Indent the list */
  margin-top: 10px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 24px;
}
.gallery-item {
  text-align: start;
}

.gallery-text {
  margin-top: 12px; /* Adjust as needed */
  font-size: 14px; /* Adjust font size as needed */
  color: #ffffff; /* or any color you prefer */
}

/* Overlay container */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  backdrop-filter: blur(8px); /* Background blur effect */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  z-index: 1000; /* Ensure overlay is on top */
}

/* Overlay content (image/video) */
.overlay-content {
  position: relative;
  max-width: 740px;
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-media {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 40px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Above overlay content */
}










@media (max-width: 1024px) {

  .projects-page {
    padding: 140px 30px 60px;
    
  }

  .projects-page h2 {
    font-size: 4rem;
  }


  .project-content {
    flex-direction: column;
  }

  .project-main {
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .main-project-image {
    padding-left: 40px;
    padding-right: 40px;
  }

  .project-meta {
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
    padding-bottom: 24px;
  }




  .project-header {
    margin-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .projects-page {
    padding: 120px 24px 40px;
    padding-bottom: 40px;

  }

  .projects-page h2 {
    font-size: 3rem;
  }

  .description-prj {
    font-size: 16px;
  }

  .main-project-image {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 60px;
  }


  .project-main h3 {
    font-size: 1.5rem;
  }

  .project-main p {
    font-size: 18px;
  }

  .navigation-links {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .project-header {
    margin-bottom: 60px;
  }

  .projects-body-text h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .projects-body-text p {
    font-size: 18px;
  }

  ul.problem-state {
    font-size: 18px;
  }
  
  
}

@media (max-width: 480px) {
  .projects-page {
    padding: 100px 16px 16px;
    padding-bottom: 24px;
  }

  .projects-page h2 {
    font-size: 2.5rem;
  }

  .main-project-image {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 40px;
  }

  .project-main h3 {
    font-size: 1.3rem;
  }

  .project-main p {
    font-size: 16px;
  }
  .meta-item h4 {
    font-size: 0.8rem;
  }

  .meta-item p,
  .audience-item {
    font-size: 0.9rem;
  }

  .tag {
    font-size: 0.8rem;
    padding: 4px 6px;
  }

  .nav-link {
    font-size: 1em;
  }

  .project-header {
    margin-bottom: 40px;
  }

  .projects-body-text p {
    font-size: 16px;
  }

  ul.problem-state {
    font-size: 16px;
  }

}