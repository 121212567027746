.error-page {
    color: white;
    min-height: 100vh;
    padding: 20px 60px;
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.error-centered p {
    font-size: 72px;
}

.back-to-projects {
    margin-top: 24px;
    cursor: pointer;
    font-size: 16px;
    padding: 14px 16px ;
}

