/* Navbar.css */

/* Base styles for all screens */

.navbar-head {
  position: relative;
}

#nav-items {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 60px;
    background-color: inherit;
    margin: 0;
  }
  
  .nav-text {
    font-size: 28px;
    color: #e4e3e3;
    text-decoration: none;
  }
  
  #navigation-options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  
  .link-text {
    display: flex;
    gap: 24px;
  }
  
  .link-text a {
    color: #e4e3e3;
    font-size: 16px;
    letter-spacing: -2%;
    text-decoration: none;
  }

  .link-text a:hover{
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .contact-link {
    text-decoration: none;
  }

  .regular-text {
    background-color: inherit;
    border: none;
    cursor: pointer;
    color: #e4e3e3;
    font-size: 16px;
    letter-spacing: -2%;
  }

  .regular-text:hover{
    color: #FFFFFF;
  }
  
  /* Contact button */
  .contact {
    display: flex;
    position: relative;
    align-items: center;
    background-color: #605DFF;
    outline: none;
    overflow: hidden;
    cursor: pointer;
    color: #FFFFFF;
    border: 1.5px solid #3532BB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    gap: 8px;
    font-family: 'Helvetica Neue', sans-serif;
    padding: 8px 16px;
    transition: background-color 0.3s;
  }
  
  .contact span {
    font-size: 16px;
    text-shadow: 0px -0.5px 0.25px rgba(0, 0, 0, 0.125);  
  }
  
  .svg-icon {
    width: 24px;
    height: 24px; 
  }
  
  .absolute-rectangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: linear-gradient(
      to bottom, 
      rgba(255, 255, 255, 0.5) 0%, 
      rgba(255, 255, 255, 0.5) 59%
    );
    filter: blur(1px);
    pointer-events: none;
    z-index: -1;
  }
  
  .contact:hover {
    background-color: #524ffe;
  }
  
  .contact:active {
    transform: scale(0.95);
    background-color: #3c37cd; 
  }
  
  .mobile-menu-overlay {
    display: none;
  }
  
  /* Hamburger Menu - Hide for desktop */
  .hamburger-menu {
    display: none;
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
/* adjustments to the nnavbar */

#nav-items {
  padding: 20px 24px;
  position:fixed;
  background-color: #121212;
  box-shadow: 0 1px 2px rgb(30, 30, 30);
  transition: background-color 0.3s, box-shadow 0.3s;
}

    .homepage .navbar-head #nav-items {
      background-color: inherit !important;
      box-shadow: none !important;
    }
  
    .nav-text {
      font-size: 20px;
    }
  
    .link-text {
      display: none; 
    }
  
    .hamburger-menu {
      display: block; 
      cursor: pointer;
      z-index: 1100;
    }
  
    .hamburger-menu .bar {
      display: block;
      width: 25px;
      height: 2.5px;
      background-color: #e4e3e3;
      border-radius: 2px;
      margin: 5px auto;
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
  
    .hamburger-menu.open .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
  
    .hamburger-menu.open .bar:nth-child(2) {
      opacity: 0;
    }
  
    .hamburger-menu.open .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  
    /* Mobile Menu Overlay */
    .mobile-menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #121212;
      display: none;
      z-index: 1000;
      overflow-y: auto;
    }
  
    .mobile-menu-overlay.open {
      display: block;
    }
  
    .mobile-menu-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      gap: 32px;
    }
  
    .mobile-menu-item {
  
      color: #ffffffc6;
      text-decoration: none;
      padding: 0px 20px;
      font-size: 60px;
      text-transform: uppercase;
      letter-spacing: -2px;
      transition: color 0.3s;
    }
    .mobile-menu-item:hover{
      color: #FFFFFF;
    }


    .button-projects-nav {
      background: inherit;
      border: none;
      cursor: pointer;
    }

  
    .desktop-contact {
      display: none;
    }


  }
  