/* Base styles */
.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 60px; 
    position: relative;
}

.lines {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #2f2f2f;
}

.svg-icons {
    width: 16px;
    height: 16px;
}

.footer-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
    gap: 12px;
    text-align: center;
}
.contact-me {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    padding: 60px 60px;
    border-radius: 32px;
    background-color: #3F3CD5;
    border: 6px solid #2a29aa;
    gap: 24px;
    width: 100%; /* Span the entire width of the page */
    box-sizing: border-box; /* Include padding in width calculation */
    transform: translateY(0);
  transition: transform 0.3s ease;
}

.contact-me:hover{
    transform: translateY(-6px);
    background-color: #292a86;  
}


.svg-envelope{
    width: 100px;
    height: 100px;
}

.scramble-text {
    margin: 0; /* Remove default h3 margin */
}

.eighty {
    padding: 80px 0; 
}

.footer-text h3 {
    color: #ffffff;
    font-size: 100px;
    font-weight: 400;
    text-align: center;
}


.copyright-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.copyright-container span {
    color: #B7B7B7;
    font-weight: 320;
    font-size: 16px;
    text-align: start;
    
}

.flexed-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.last-part {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.social-links a {
    text-decoration: none;
    color: #B7B7B7;
}

.social-links a:hover {
    color: #FFFFFF;
}

.animate-in {
    opacity: 1;
    transform: translateY(0);
}

.footer-text h3 {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.scramble-text {
    font-size: 1rem; 
    white-space: nowrap; 
    overflow: hidden; 
    text-align: start;
}

/* Media Queries */
@media (max-width: 1200px) {
    .footer-section {
        padding: 0 60px; 
        min-height: 80vh;
    }

    .footer-text h3 {
        font-size: 80px; 
    }

    .footer-text p {
        font-size: 20px; 
    }

    .eighty {
        padding: 40px 0; 
    }

    .contact-me {
        padding: 32px 20px;
        border-radius: 32px;
        border: 6px solid #2a29aa;
        gap: 24px;
    }

    .footer-text h3 {
        font-size: 80px;
    }

    .svg-envelope{
        width: 80px;
        height: 80px;
    }
    
}

@media (max-width: 768px) {
    .footer-section {
        padding: 0 24px; 
        min-height: 60vh;
    }

    .last-part {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 24px;
    }

    .footer-text h3 {
        font-size: 60px; 
    }

    .footer-text p {
        font-size: 18px;
    }

    .eighty {
        padding: 40px 0; 
    }

    .contact-me {
        padding: 32px 20px;
        border-radius: 20px;
        border: 4px solid #2a29aa;
        gap: 24px;
    }

    .footer-text h3 {
        font-size: 40px;
    }

    .svg-envelope{
        width: 40px;
        height: 40px;
    }
    
}

@media (max-width: 480px) {
    .footer-section {
        padding: 0 20px; 
        min-height: 60vh;
   
    }


    .footer-text {
        align-items: flex-start; 
        text-align: start; 
    }

    .footer-text h3 {
        font-size: 40px; 
    }

    .footer-text p {
        font-size: 16px; 
        width: 100%; 
    }

    .eighty {
        padding: 32px 0;
    }
}