

.about-container {
  min-height: 100vh;
  color: #ffffff;
  padding: 0 60px;
  padding-top: 150px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  width: 100%;
  max-width: 800px; 
  margin: 0 auto; 
}


.about-container p {
    color: #979797;
    font-size: 24px;
    font-weight: 300;
}

.about-container section,
.about-container div {
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;

}




.spotify-embed iframe {
    width: 100%;
    border: none; /* Remove the border */
}

.about-intro {
  margin: 20px 0;
  padding-top: 20px;
  position: relative;
}

.about-intro p {
  margin-bottom: 12px;
}

.drop-cap-image {
  float: left;
  width: 150px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  margin: 0 20px 10px 0; /* Adjust spacing around the image */
  border-radius: 20px;
  object-fit: cover;
}




  .spotify-embed {
    margin-top: 20px;
  }




  /* socials */
  .contact-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contact-div a {
    text-decoration: none;
    font-weight: 300;
    color: #979797;
    font-size: 24px;
    transition: color 0.3s ease;
  }

  .contact-div a:hover {
    color: #4F4CDA;
  }

  .contact-div p {
    font-size: 14px;
  }

  .contact-moi h3,
  .spotify-embed h2,
  .favorites-section h2,
  .skills-section h3 {
    padding-bottom: 32px;
    font-weight: 300;
    font-size: 32px !important;
    color: #ffffffc6;
  }



  .contact-moi {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  /* Divider */
.divider {
  width: 100%;
  height: 1px;
  background-color: #383838;
  margin: 10px 0;
}

  /* grid */
  .favorites-section h3 {
    margin-bottom: 8px;
    font-weight: 300;
  }
  
  .favorites-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  
  .favorite-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%; /* Ensures cards fill their grid cell */
  }
  
  .favorite-image-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 10px; /* Add border-radius here */
    cursor:pointer;
  }

  .favorite-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .favorite-image:hover {
    transform: scale(1.05); /* Scale the image on hover */
  }

  .favorite-content {
    padding-top: 10px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
  }
  
  .favorite-title {
    font-size: 20px;
    font-weight: bold;
    color: white;
  }
  
  .favorite-author,
  .favorite-subtitle {
    font-size: 16px !important;
    color: #666;
    margin: 0;
  }

  /* image */
/* Skills Section */
.skills-section {
  margin-top: 50px;
}

.skills-section h3 {
  margin-bottom: 10px;
  font-size: 22px;
  color: #ffffffc6;
}
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.skills-grid li {
  font-size: 18px;
  color: #979797;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  list-style: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.skills-grid li:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.08);
}



/* Responsive Styles */
@media (max-width: 992px) {
  .about-container {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 116px;
  }


  .about-container p {
    font-size: 22px;
  }

  .contact-div a {
    font-size: 22px;
  }

  .contact-div p {
    font-size: 13px;
  }

  .drop-cap-image {
    width: 120px;
    height: 120px;
    margin: 0 16px 8px 0;
  }
}

@media (max-width: 768px) {
  .about-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 80px;
  }

  .about-container p {
    font-size: 20px;
  }

  .contact-div a {
    font-size: 20px;
  }

  .contact-div p {
    font-size: 12px;
  }

  .drop-cap-image {
    width: 100px;
    height: 100px;
    margin: 0 14px 8px 0;
  }

  .skills-grid {
    grid-template-columns: 1fr;
  }

  .favorites-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .about-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 65px;
  }

  .about-container p {
    font-size: 18px;
  }

  .contact-div a {
    font-size: 18px;
  }

  .contact-div p {
    font-size: 11px;
  }

  .drop-cap-image {
    width: 80px;
    height: 80px;
    margin: 0 12px 6px 0;
  }
}

