
/* General styles */

@font-face {
  font-family: 'Brittany Signature';
  src: url('./Fonts/BrittanySignature.woff2') format('woff2'),
       url('./Fonts/BrittanySignature.woff') format('woff'),
       url('./Fonts/BrittanySignature.ttf') format('truetype'),
       url('./Fonts/BrittanySignature.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'Raleway', sans-serif;
  background-color: #121212;
  user-select: none;
}

.italic-text {
  font-family: 'Raleway', sans-serif;
  font-style: italic;
}

.light-text {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

.signature {
  font-family: 'Brittany Signature', cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: 'Raleway', sans-serif;
}
