.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #121212;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loader {
  text-align: center;
  color: #fff;
  position: relative;
  height: 100vh; /* Adjust height if needed */
  width: 100%; /* Ensure full width */
}

.greeting-text {
  font-family: "Brittany Signature", sans-serif;
  font-size: 4em; /* Base font size */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Ensure full width */
  margin: 0; /* Reset any default margins */
}

.progress-text {
  font-size: 20px; /* Base font size */
  text-align: center;
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Ensure full width */
}

/* Media Queries for Responsiveness */

/* Small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .greeting-text {
    font-size: 2em; /* Smaller font size for small devices */
  }

  .progress-text {
    font-size: 16px; /* Smaller font size for small devices */
  }
  
  .loader {
    height: 70vh; /* Adjust height for small devices */
  }
}

/* Medium devices (tablets, 768px and down) */
@media (max-width: 768px) {
  .greeting-text {
    font-size: 3em; /* Medium font size for tablets */
  }

  .progress-text {
    font-size: 18px; /* Medium font size for tablets */
  }
  
  .loader {
    height: 80vh; /* Adjust height for tablets */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .greeting-text {
    font-size: 4em; /* Larger font size for desktops */
  }

  .progress-text {
    font-size: 20px; /* Larger font size for desktops */
  }
  
  .loader {
    height: 100vh; /* Full height for desktops */
  }
}
