.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #fff;
    text-align: center;
  }
  
  .error-boundary h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
