.homepage {
  position: relative;
  min-height: 98vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center; 
  color: white;
  /* background: #3f3cd5; */
  background-color: #4f4cda;
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;
  scroll-snap-align: start;
}

.background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: inherit;
}

.header-text,
.body-text-one {
  z-index: 1;
}

.body-text-one {
  position: relative;
}

.header-text {
  font-size: 4em;
  color: #ffffff;
  text-align: center;
}

/* h1 */

#main-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  padding-bottom: 16px;
}

.body-text-one {
  font-size: 20px;
  margin-top: 0;
  color: #e2e2e2;
}

/* grid.css */
.squares-grid {
  display: grid;
  grid-template-columns: repeat(40, 80px);
  grid-auto-rows: 80px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: #3f3cd5;
  overflow: hidden;
}

.square {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border: 0.5px solid rgba(255, 255, 255, 0.06);
  border-bottom: none;
  background-color: transparent;
  transition: background-color 0.5s;
}

.square:hover {
  background-color: rgba(255, 255, 255, 0.088);
}

.square:not(:nth-last-child(-n + 40)) {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.06);
}


/* section 2 */

.section-two {
  background-color: #121212;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  min-height: 90vh;
}

.center-aligned {
  text-align: left;
  max-width: 860px;
  padding: 60px;
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#section-one-flex {
  display: flex;
  justify-content: flex-start;
 align-items: center;
  gap: 16px;
}

#story-text{
  padding-top: 12px;
}



.pin-icon {
  width: 20px;
  height: auto;
  margin-right: 4px;
}

.location {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 8px 12px;
  background: rgba(54, 54, 54, 0.8);
  border-radius: 100px;
}
.location::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100px;
  pointer-events: none;
  border: 1px solid transparent;
  background: linear-gradient(
    to top,
    rgba(85, 85, 85, 0.402) 20%,
    rgba(85, 85, 85, 0) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.location span {
  font-size: 20px;
  text-shadow: 0px -0.5px 0.25px rgba(0, 0, 0, 0.29);
  color: #cacaca;
}

.about-me {
  margin-top: 24px;
}

.about-me .second-text {
  font-size: 2.8em;
  font-weight: 300 !important;
}

.second-text {
  color: #ffffff;
  line-height: 56px;
  word-wrap: break-word;
  white-space: normal;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.dark-text {
  color: #979797;
}

/* animation */
.reveal-content {
  opacity: 0;
  transform: translateY(100%);
}

/* section 3 */

.section-4 {
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
}

.see-more-projects {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #fff;
  text-decoration: none; 
}

.hover-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-left: 8px; 
}

.see-more-projects:hover .hover-icon {
  opacity: 1;
}

.projects-title {
  font-size: 60px;
  color: #ffffff;
  font-weight: 400;
  margin-top: -10px;
  text-align: start;
}


.project-boxes {
  max-width: 860px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding-left: 60px;
  padding-right: 60px;
  gap: 3rem;
}

.project {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  will-change: opacity, transform; 
}

.project-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  margin-top: 32px;
}

.image-container {
  position: relative;
}

.image-container-two {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; 
}

.project-image:hover {
  transform: scale(1.05);
  opacity: 0.9; 
}

.projects-titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  width: 350px;
}
.projects-para {
  font-size: 20px;
  font-weight: 300;
  color: #979797;
}
.projects-titles h3 {
  font-size: 40px;
  text-align: start;
  color: #ffffff;
  font-weight: 300;
}
.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.tag {
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 0.88rem;
  background: #2e2e2e;
  letter-spacing: 2%;
  color: #cacacacc;
  font-weight: 320;
  border: 0.6px solid rgba(255, 255, 255, 0.057);
  text-shadow: 0px -0.5px 0.25px rgba(0, 0, 0, 0.29);
}




/* other works */
.other-works-wrapper {
  display: flex;
  overflow-x: auto;
  padding: 20px 24px;
  padding-bottom: 150px;
  justify-content: flex-start;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE 10+ */
  white-space: nowrap;

}
.other-works-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.other-works-item {
  display: inline-block;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start */
  margin-right: 20px;
  transform: translateX(0); /* Initial position */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.other-works-image {
  height: 500px; /* Adjust height as needed */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 20px; /* Add border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
}

.other-works-name {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #979797; /* Change text color to white for better visibility on black background */
  text-align: left; /* Align the name to the right */
}




/* responsivity */
@media (max-width: 1200px) {
  .homepage {
    padding-left: 40px;
    padding-right: 40px;
    min-height: 80vh;
  }


  .header-text {
    font-size: 3rem;
  }

  .body-text-one {
    font-size: 1.25rem;
  }

  .section-two {
    padding: 40px;
    min-height: 80vh;
  }

  .center-aligned {
    padding: 0px;
  }

  .other-works-item {
    margin-right: 15px;
  }

  .other-works-image {
    height: 440px; /* Adjust height for smaller screens */

  }
  .other-works-wrapper {
    padding-bottom: 100px;

  }
}





@media (max-width: 992px) {
  .homepage {
    padding-left: 24px;
    padding-right: 24px;
  }

  .header-text {
    font-size: 2.5em;
  }

  .body-text-one {
    font-size: 16px;
  }



  .section-4 {
    padding-bottom: 80px;
  }

  .projects-title-label {
    padding-top: 60px;
    padding-bottom: 10px;
  }

  .section-two {
    padding: 40px;
    min-height: 60vh;
  }

  .center-aligned {
    padding: 0px;
  }

  .about-me .second-text {
    font-size: 2.5em;
  }

  .second-text {
    line-height: 52px;
  }
  

  .image-container {
    width: 50px;
    height: 50px;
  }

  .location span {
    font-size: 18px;
  }

  .pin-icon {
    width: 18px;
  }

  .other-works-item {
    margin-right: 10px;
  }

  .other-works-image {
    height: 340px; /* Adjust height further for smaller screens */
    border-radius: 15px;
  }
  .other-works-wrapper {
    padding-bottom: 60px;

  }

}

@media (max-width: 768px) {
  .homepage {
    padding-left: 24px;
    padding-right: 24px;
  }

  .header-text {
    font-size: 2.2rem;
  }

  .body-text-one {
    font-size: 1rem;
  } 

  .project-boxes {
    padding-left: 20px;
    padding-right: 20px;
    gap: 32px;
  }

  .projects-title {
    font-size: 44px;
  }

  .projects-titles {
    width: 100%;
  }

  .projects-titles h3 {
    font-size: 32px;
  }

  .project-image {
    height: 280px;
  }

  .image-container-two {
    border-radius: 12px;
  }

  .section-4 {
    padding-bottom: 40px;
    min-height: 60vh;
  }

  

  .projects-title-label {
    padding-top: 40px;
    padding-bottom: 5px;
  }
  .section-two {
    padding: 24px;
 
  }

  .center-aligned {
    padding: 0px;
  }


  .about-me .second-text {
    font-size: 1.8em;
  }

  .second-text {
    line-height: 38px;
  }



  .project-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;
    gap: 12px;
  }

  .other-works-item {
    margin-right: 5px;
  }

  .other-works-image {
    height: 240px; /* Adjust height for mobile screens */
    border-radius: 8px;
  }

  
}

@media (max-width: 480px) {
  .homepage {
    padding-left: 16px;
    padding-right: 16px;
    min-height: 80vh;
  }
  .header-text {
    font-size: 1.8rem;
  }

  .body-text-one {
    font-size: 18px;
  }

  .section-two {
    min-height: 72vh;
    padding: 16px;
  }

  .projects-title {
    font-size: 32px;
  }

  .center-aligned {
    padding: 0px;
  }
  

  .second-text {
    font-size: 1.25em;
  }

  #main-text {
    padding-bottom: 12px;
  }


  #section-one-flex {
    flex-direction: column;
    gap: 8px;
  }

  .project-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: start;
  }

  #nav-items {
    padding: 16px;
  }

  .nav-text {
    font-size: 18px;
  }

  .hamburger-menu .bar {
    width: 20px;
    height: 2px;
    margin: 4px auto;
  }

  .mobile-menu-content {
    gap: 24px;
  }

  .mobile-menu-item {
    font-size: 50px;
    padding: 0px 16px;
  }

  .contact {
    padding: 8px 12px;
    gap: 6px;
  }

  .contact span {
    font-size: 14px;
  }

  .section-4 {
    padding-bottom: 30px;
  }

  .project-boxes {
    padding-left: 20px;
    padding-right: 20px;
    gap: 16px;
  }


}

