.playground-container {
    padding: 0 60px;
  padding-top: 180px;
  padding-bottom: 100px;
  min-height: 100vh;
  overflow-y: auto;
}

.playground-title {
    font-size: 2em;
    font-weight: 400;
    margin: 0;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 16px;
  }
  
  .playground-description {
    font-size: 18px;
    color: #979797;
    margin-top: 8px;
    margin-bottom: 60px;
    text-align: left;
  }
  
  .playground-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px; /* Column gap */
  }
  
  .playground-item {
    text-align: center;
    padding-right: 12px;
    cursor: pointer;
  }

  
  .media {
    width: 100%;
    height: 420px;
    object-fit: cover;
    border-radius: 16px;
  }
  
  .media-name {
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 8px;
    text-align: start;
  }

  .media-year {
    font-size: 14px;
    color: #979797;
    margin-top: 8px;
    text-align: start;
  }

  /* Overlay container */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    backdrop-filter: blur(8px); /* Background blur effect */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    z-index: 1000; /* Ensure overlay is on top */
    pointer-events: auto; /* Overlay receives pointer events */
  }
  
  
  /* Overlay content (image/video) */
  .overlay-content {
    position: relative;
    max-width: 740px;
    max-height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay-media {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

 .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 40px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Above overlay content */
}



/* Media Queries for further adjustments */
@media (max-width: 768px) {

    .playground-container {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 110px;
        padding-bottom: 60px;
      }

      .playground-title {
        font-size: 24px;
        margin-bottom: 12px;
      }

      .playground-description {
        margin-bottom: 40px;

      }

      .playground-item {
        padding-right: 8px;
      }
    

    .playground-grid {
        grid-template-columns: 1fr;
      gap: 16px; /* Adjust gap */
    }
  
    .media {
      height: 300px; /* Adjust height for smaller screens */
      border-radius: 16px;
    }

  
    .media-name {
      font-size: 1em; /* Adjust media name size */
    }

    .overlay-content {
        max-width: 90%;
      }
  }
  
  @media (max-width: 480px) {


    .playground-container {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 80px;
        padding-bottom: 40px;
      }
      
  
    .playground-grid {
      grid-template-columns: 1fr; /* Stack items on very small screens */
    }
  
    .media {
      height: 200px; /* Further adjust height for very small screens */
      border-radius: 12px;
    }
  
    .media-name {
      font-size: 0.9em; /* Further adjust media name size */
    }

  }
  
 